.standing_tabs .nav-link {
    border-bottom: 3px solid #A2A2A2 !important;
    transition: all .2s ease-in-out;
}

.standing_tabs .nav-link.active {
    border-color: #EF2A2A !important;
    color: #EF2A2A !important;
}

.standing_tabs .nav-tabs {
    width: fit-content;
}

.standing_tabs img {
    height: 5rem;
}

.standing_tabs table img {
    height: 3rem !important;
}

.standing_tabs .vs-sign {
    color: #2AE3EF !important;
}

.standing_tabs table {
    --cui-table-bg:#2A2729;
    --cui-table-color:#fff;
}

.standing_tabs table thead {
    background-color:#202020;
}

.standing_tabs svg {
    width: 1.5rem;
    margin-right: .3rem;
}

.standing_tabs .score_heading {
    background-color: #111111;
}

.standing_tabs svg, .standing_tabs path {
    fill: #EF2A2A;
  }

.standings-match table th {
    background-color: #EF2A2A;
}
.standings-match .standing_badge_1, .standings-match .standing_badge_2, .standings-match .standing_badge_3 {
    border-radius: 2px;
}
.standings-match .standing_badge_1 {
    background-color: #F22EAD;
}

.standings-match .standing_badge_2 {
    background-color: #0B3969;
}

.standings-match .standing_badge_3 {
    background-color: #15D3A6;
}

.winner-match svg, .winner-match path {
    fill: #F4F821;
}

.winner-match table th, .match-score-table th {
    background-color: #202020 !important;
}

.winner-match table svg {
    width: 1rem;
}

.score-card svg, .score-card path {
    fill: none;
}

.my-group-btn {
    background-color: #212631 !important;
    border-color: #212631 !important;
}
p{
    margin-bottom:0px!important;
}
@media (max-width: 767px) {
    .standing_tabs .nav-link{
    font-size: 3.5vw!important;
    padding: .5vw 2vw .5vw 2vw!important;
  }
  .card-body{
    background-image: none!important;
  }
  .bg-image{
    background-position: 50%!important;
  }
  tr td{
    font-size: 3.5vw!important;
  }
  .standing_tabs  img{
    height: 3.2rem!important;
  }
  #groups{
    display: flex;
    align-items: self-end;
    align-items:end;  
  }
  .primarytext{
    display: block;
    padding-right: 5vw !important;
    font-size: 4vw !important;
  }
   .text-group{
    font-size: 4vw!important;
    display: flex !important;
    justify-content: end !important;
    padding-right: 0vw !important;  }
  p{
    padding-bottom: 0%!important;
    font-size: 4.5vw!important;
  }
  .teamlogo{
        padding: 0px 20px 0px 0px !important;
  }
  .mymatchcard{
            padding: 0 1vw 0 1vw !important;
  }
  .team-name{
            margin: 0vw 3.8vw 0vw 3.5vw !important;
  }
  #match-row{
    padding-left: 5%!important;
  }
  #matchscore{
        
        padding-left: 0% !important;
        padding-right: 4% !important;
  }
  .ground-name{
        padding-right: 19%!important;
  }
  .match-winner,.score_heading{
    font-size: 3.8vw!important;
  }
  .davidcup-span{
    font-size: 3.2vw!important;
  }
#trophycard{
    padding: 0vw 2vw 0vw 2vw!important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
      .standing_tabs .nav-link{
    font-size: 2.8vw!important;
    padding: .5vw 3vw .5vw 3vw!important;
  }
  #matchscore{

    padding-left: .7vw!important;
  }
}
.standing-head {
  --cui-table-bg:red;
  --cui-table-color:#fff;
}
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
     .standing_tabs .nav-link{
    font-size: 3vw!important;
    padding: 0.5vw 1.5vw .5vw 1.5vw !important;
  }
}

.custom-scrollbar {
	height: 200px;
	overflow-y: scroll;
  
	scrollbar-width: thin;
	scrollbar-color: #fdfdff  #2A2729;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
	width: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
	background: #f0f0f0;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 5px;
	border: 2px solid #f0f0f0;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color: #999;
  }
  
  