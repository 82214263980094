@media (max-width: 767px){

	#navItem{
		max-width: 20%!important;
		display: flex!important;
		justify-content: ;
	}
	.myMatch{
		display: flex!important;
    	justify-content: center!important;
    	max-height: 22vw !important;
	}
	#trophyimg{
		padding: 5vw 1vw 0vw 1vw!important;
		display: flex!important;
		justify-content: left!important;
	}
	.match_schedule{
		padding-left: 1vw!important;
		margin-top: 2%!important;

	}
	.mymatchcard{
		padding: 0 1vw 0 1vw !important;
	}
	.mymatchcard{
		font-size: 3.5vw!important;
	}
	.team-name{
		margin: 0vw 3.8vw 0vw 3.5vw!important;
	}
	.ground-name{
		padding-right: 19% !important;

	}
	.myMatch{
		font-size: 3.2vw!important;
		padding: 1vw 2vw 1vw 2vw!important;
	}
}


.custom-scrollbar {
	height: 200px;
	overflow-y: scroll;
  
	scrollbar-width: thin;
	scrollbar-color: #fdfdff  #2A2729;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
	width: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
	background: #f0f0f0;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 5px;
	border: 2px solid #f0f0f0;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color: #999;
  }
  
  