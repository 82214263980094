.pagination {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    /* padding: 0; */
    /* margin: 0; */
    /* mar */
    margin-bottom: 100px;
}

.pagination a {
    display: block;
    padding: 8px 12px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-decoration: none;
    margin: 0 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination a.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.pagination a.disabled {
    cursor: default;
    background-color: #ddd;
    color: #777;
}

.pagination a:hover {
    background-color: #e9ecef;
}

.pagination a:focus {
    outline: none;
}