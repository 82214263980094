.dropdown-header{
	display: 'flex'; 
	align-items: 'center';
	justify-content:"space-between" ;
	background-color:"#E5E5E5"
}
.dropdown-header h5{
	color: '#121212' ;
	padding-left:"20px" ; 
	padding-top:"6px"
}
.image-container img{
	width: 30px !important;
    cursor: pointer !important;
    padding: 5px !important;
    background-color: rgb(255, 0, 0, 0.1) !important;
}
.dropdown-menu{
    width: 450px !important;
        padding: 0px !important;
        position: absolute !important;
        right: 0px !important;
        inset: 0px 0px auto auto !important;
        transform: translate(5.799em, 4em) !important;
}
.dropdowns-item{
    padding:0px 16px 16px 16px !important;
    
}
.applybutton{
    padding:0px 16px 16px 16px !important;
}
.applybutton button{
    font-weight: 700;
    /* color: linear-gradient(#001E28 100% #EF2A2A 100%) !important; */
    background-color: white;
    border: 0px;
    --color-start: #001E28; /* Starting color */
  --color-end: #EF2A2A; /* Ending color */
  --gradient: linear-gradient(to right, var(--color-start), var(--color-end));

  /* Apply the gradient as text color */
  color: transparent;
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
    /* color: transparent; */
  /* text-shadow: 0 0 5px rgba(0, 30, 40, 1), 0 0 10px rgba(239, 42, 42, 1); */
}
.outer-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
}