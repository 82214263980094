.upcomming-match {
    background-color: #DC4545 !important;
}

.upcomming-match img {
    width: 3rem;
}

.border-radius25px {
    border-radius: 25px !important;
}

.border-radiusrb20px {
    border-bottom-right-radius: 20px;
}

.nav-active-tab {
    background-color: #EF2A2A !important;
}

.complete-nav-card {
    background-color: #2A2729 !important;
    background-position: top right;
}

.complete-nav-card img {
    height: 3.2rem;
}

.tab-contebt-right-border {
    border-right: 2px solid#545454 !important;
}

.match_schedule svg {
    width:24px
}

.yellow-dot {
  width: 10px;
  height: 10px;
  background-color:#EBE20D;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.custom-border-end-3 {
    border-right: 3px solid !important;
}

.upcomming-time-location {
    background:#2A2729 !important;
}

.upcomming-time-location svg {
    width: 1.3rem;
}


@media (max-width: 767px) 
{
    p{
        padding: 0%!important;
        margin-bottom: 0%!important;
        font-size: 4.5vw!important;
        font-weight: bold!important;
        display: block;
        text-align: center!important;
      }
    .match_schedule svg {
        width:24px!important;
        height: 26px!important;
    }
    .location-logo svg
    {
        min-width: 24px!important;
        min-height: 26px!important;
    }
}


