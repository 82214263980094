@media (max-width: 767px){
	.list-unstyled li{
		padding:2vw!important;
		display: block!important;
		justify-content:0vw!important;
		font-size: 3vw!important;
	}
}
.custom-dropdown-menu {
	width: 300px; /* Adjust the width as per your requirement */
  }
.dropdown-header{
	display: 'flex'; 
	align-items: 'center';
	justify-content:"space-between" ;
	background-color:"#E5E5E5"
}
.dropdown-header h5{
	color: '#121212' ;
	padding-left:"20px" ; 
	padding-top:"6px"
}
.image-container img{
	 width: 30px;
     cursor: pointer;
     padding: 5px;
     background-color: rgb(255, 0, 0, 0.1);
}