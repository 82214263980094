body {
    background-color: white;
}
.dash-sidebar {
    background-color: #111111 !important;
    /* --cui-sidebar-width: unset !important; */
}

.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-35 { width: 35% !important; }
.w-80 { width: 80% !important; }
.dash-sidebar .nav-link, .dash-sidebar .nav-icon {
    color: #fff !important;
}
.list-style-none {
    list-style:none !important;
}

.dash-sidebar .nav-title {
    color: #5F5F5F !important;
    text-transform: capitalize !important;
}

.dash-sidebar .nav-link {
    padding: 5px 16px !important;
}

.dash-sidebar .nav-link svg {
    width: 20px;
    margin-right: 1rem;
}

.dash-sidebar .nav-link:hover, .dash-sidebar .nav-link.active  {
    background-color: #EF2A2A !important;
}

.dash-sidebar .nav-link:hover.nav-group-toggle::after, .dash-sidebar .nav-link.nav-group-toggle::after {
    background-color: #fff !important;
}

.HeaderSearchBar input, .HeaderSearchBar .input-group-text {
    background-color: #363839 !important;
    color: #A6A6A6 !important;
    border-right: none;
}


.HeaderSearchBar input::placeholder {
    color: #A6A6A6 !important;
}

.HeaderSearchBar input:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 767px){
    .main-container{
        padding: 0 4.5vw 0 4.5vw!important;
    }
}