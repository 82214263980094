@media (max-width: 767px){
  .card-text{
  	display: flex!important;
  	justify-content: center!important;
  }
  #timepara{
    max-width: 30%!important;
    margin: auto!important;
    margin-top: 3vw!important;
    margin-bottom: 3vw!important;
    margin-right: 26% !important;

  }
}
.scorer-img-today{
  height: 100px;
  width:100px;
  border: 2px solid #F22EAD;
  background-size: cover;
  border-radius: 3px;
  background-position:top;
}

.custom-scrollbar {
  height: 200px;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: #fdfdff  #DC4545;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  border: 2px solid #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

