* {
  font-family: 'roboto';
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.login-form input::placeholder {
  color: #7f7f7f;
}
.login-form select::placeholder {
  color: #7f7f7f;
}
.bg-gray {
  background-color: #1e1e1e; /* Gray color code */
}
.border-gray {
  border: 1px solid #454545 !important;
  }
.otp-letter-input{
	max-width: 50px;;
	height: 50px;
	background: transparent;
	border: 1px solid #3E8B96;
	border-radius:3px;
	/* color: #198754; */
	font-size: 60px;
	text-align: center;
	font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.heading-color {
  color: #F22EAD;
}

.below-button {
	color: #404040;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
}

.header-logo {
  width: 4rem;
}

.game_bar ul{
  width: fit-content;
}

.game_bar svg{
  width: 1.5rem;
}

.game_bar li{
  cursor: pointer;
}

.list-style-none {
  list-style: none !important;
}

.game_bar li.active, .game_bar li:hover{
  color: #2AEFC0 !important;
  transition: all .2s ease-in-out;
}

 .game_bar .active svg, .game_bar .active path, .game_bar li:hover path , .game_bar li:hover svg {
    fill: #2AEFC0;
    transition: all .2s ease-in-out;
  }

  .darkSvg svg, .darkSvg path {
     fill: #000;
  }

.header-nav-dropdown .dropdown-menu  {
  background-color: #363839 !important;
}

.header-nav-dropdown .dropdown-menu li  {
    transition: all .2s ease-in-out;
}
.header-nav-dropdown .dropdown-menu li a  {
    background-color: transparent !important;
}

.header-nav-dropdown .dropdown-menu li:hover  {
  background-color: #EF2A2A !important;
}

.p-header {
  background-color: #001E28;
}

.rounde-bottom-right-1rem {
  border-bottom-right-radius:1rem !important;
}

.flex-none { flex:unset !important; }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

.z-index-9 {
	z-index: 9;
}

.w-fit-content {
	width: fit-content;
}

.main-box {
  background-color: #FEFEFE;
  height: 100%;
  width: 100%;
}
.logo {
  width: 60px;
  /* height:60px; */
}

.form-row {
      height: calc(100vh - (60px + 3rem));
}
.login-form {
  letter-spacing: 1;
  /* color: #F22EAD; */
}

.form-title {
    font-size: 20px;
    line-height: 22px;
    text-align: left;
	color: #F22EAD;
	font-weight: bold;
}

.form-heading {
	color:#404040;
	font-size: 18px;
	line-height: 28px;
	text-align: left;

}

.input-label {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #454545;
}

.form-input {
	color: #121212 !important;
    border-color: #454545 !important;
}

.form-input:focus {
	box-shadow: none !important;
	border-color: #8D65FF !important;
}

.login-navigation, .signup-terms {
	font-size: 18px;
	line-height: 22px;
	text-align: left;
	color: #404040;
	text-decoration: none;
}

.signup-terms {
	color: #454545;
}

.submit-button {
	font-size: 25px !important;
	font-weight: 700 !important;
	line-height: 22px !important;
	background-color:#EF2A2A !important;
	color: #fff;
}
.form-side-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#togglePassword {
  cursor: pointer;
  color: #fff;
}

#togglePassword:hover {
    background-color: transparent;
    border:none;
}


.slick-dots {
	bottom: 17px !important;
}

.slick-dots li button:before {
    width: 13px !important;
    height: 13px !important;
    opacity: 1 !important;
	font-size: 0 !important;
    color: transparent !important;
    border: 2px solid #fff;
    border-radius: 100%;
}

.slick-dots li.slick-active button:before {
    background: #fff;
}

.slider-custom-arrow {
	height: 39px;
	width: 39px;
	line-height: 39px;
	background-color: #344FAD;
}

.profile-camera {
	background-color: #D1E2FC;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 25px;
}

.register-profile-img {
	height: 100px;
	width: 100px;
}

.verification-code {
	font-size: 22px;
	font-weight: 500;
	line-height: 22px;
	color: #121212;
}


/* scrollbar*/

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #282c34;
    border: 5px solid transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #282c34;
    border: 5px solid transparent;
  }
  ::-webkit-scrollbar-track
  {
    background-color: #fff;
  } 

  .scroll-none::-webkit-scrollbar {
    width: 0px;
  }
/* scrollbar*/ 
/* profile image */
  .image--cover {
    width: 150px;
    height: 150px;
    border-radius: 5%;
    margin: 20px;
    border: 1px solid #F562C1;      ;
    object-fit: cover;
    object-position: center right;
  }     

/* player style */
  .vs-Card {
    text-align: center; /* Center the text and image */
  }
  .bg-image-team{
    width: 6em;
    height: 6em; 
    border-radius: 4px;
    background-size: cover; 
    margin: auto auto 1em auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bg-image-container {
    width: 50%;
    height: 150px; /* Set a fixed height for the container */
    border-radius: 4px;
    background-size: cover; /* Ensure the image covers the container */
    background-position: top; /* Center the image in the container */
    background-repeat: no-repeat; /* Prevent the image from repeating */
  }
  .vs-Card p{
    color: darkgoldenrod;
    font-weight: 600;
  }
  .middle-name{
    text-transform: capitalize;
    color: darkcyan;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .player-name {
    text-align: center; /* Center the name text */
    margin-top: 10px; /* Space between the image and the text */
  }

/* end player style */
  .heading{
    color: #F22EAD !important;
    margin-left: 3vw;
    margin-top: 5vh;
  }
  .col-desc{
    margin-left: 3vw;
    margin-top: 2vh;
    width: auto;
    color: rgba(18, 18, 18, 1);
    font-family: roboto;
    /* font-size: auto; */
  }

  .image{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2vh;
    padding-bottom: 8vh;
    margin-left: 40px;
    /* padding: 0px; */
  }
  .image img{
    width: 150px;
    height: 150px;
    border: 2px solid rgba(245, 98, 193, 1);
    border-radius: 5px;
  }
  .select-sport{
    margin-left: 1.6vw !important;
  }
  .select-sport p{
    color: rgba(69, 69, 69, 1);
    font-family: roboto;
    margin-left: 27px;
  }
  .slected-btn{
    /* background-color: green; */
    background-color: white;
  }
  .slected-btn:hover{
    background-color: white;
  }
  .cancel-btn{
    margin-left: 15px;
    border: 1px solid rgba(16, 16, 16, 1);
  }
  .btn-2:hover{
  background-color: white;
  border: 1px solid rgba(16, 16, 16, 1);
  color: rgba(16, 16, 16, 1);
  }
  .container-2{
    /* width: 80%;    */
    /* font-size: 1.25rem; */
  }
  .option-row{
    padding: 15px 20px 10px 80px;
  }
  .option-row div{
    font-style: roboto;
    color: #121212;
  }
  .btn-1{
    width: 110px;
    border-radius: 2px;
    background-color: white;
    color: black;
    border: 1px solid grey;
  }
  .btn-2{
    width: 58px;
    border-radius: 2px;
    color: black;
  }
  .btn-2:hover{
    /* background-color: red; */
  }
  .upper-col{
    margin-bottom: 20px;
    padding: 0!important;
  }
  .lower-col{
    padding: 0!important;
  }
  .submitbtn{
    padding: 25px 16px 10px 40px;
  }
  .submitbtn button{
    width: 100%;
    height: 50px;
  }
  .icons{
    height: 20px;
    width: 20px;
    /* padding-right: 10px; */
  }
  .innerspan{
    padding-right: 10px;
  }

  .scorer-img{
    height: 125px;
    border: 2px solid #F22EAD;
    background-size: cover;
    border-radius: 3px;
    background-position:top;
  }

  .scorer-card-logo{
    padding-top: 20px;
  }

  .scorer-db{
    position: relative; 
    border-radius: 23px !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  ::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-thumb {
    background-color: #282c34;
    border: 5px solid transparent;
}
::-webkit-scrollbar-thumb {
    background-color: #282c34;
    border: 5px solid transparent;
}
::-webkit-scrollbar-track
{
    background-color: #fff;
} 

.scroll-none::-webkit-scrollbar {
    width: 0px;
}
 /* scrollbar*/ 
 /* profile image */
.image--cover {
  width: 150px;
  height: 150px;
  border-radius: 5%;
  margin: 20px;
  border: 1px solid #F562C1;      ;
  object-fit: cover;
  object-position: center right;
}     

/* player style */
.vs-Card {
  text-align: center; /* Center the text and image */
}
.bg-image-team{
  width: 6em;
  height: 6em; 
  border-radius: 4px;
  background-size: cover; 
  margin: auto auto 1em auto;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-image-container {
   width: 50%;
  height: 150px; /* Set a fixed height for the container */
  border-radius: 4px;
  background-size: cover; /* Ensure the image covers the container */
  background-position: top; /* Center the image in the container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}
.vs-Card p{
  color: darkgoldenrod;
  font-weight: 600;
}
.middle-name{
  text-transform: capitalize;
  color: darkcyan;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 15px;
}
.player-name {
  text-align: center; /* Center the name text */
  margin-top: 10px; /* Space between the image and the text */
}


.bg-image-container2 {
  width: 100px;
  border: 2px solid rgb(244, 248, 33);
height: 100px;
border-radius: 8px;
 background-size: cover; /* Ensure the image covers the container */
 background-position: top; /* Center the image in the container */
 background-repeat: no-repeat; /* Prevent the image from repeating */
}
.scorer-name{
text-transform:capitalize;  
}
.scorer-date{
font-size:16px;
line-height:2;
}

@media (min-width: 576px) {
  .col-desc {
    font-size: 1rem; /* Font size for small devices */
  }
}    

    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #282c34;
        border: 5px solid transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #282c34;
        border: 5px solid transparent;
    }
    ::-webkit-scrollbar-track
    {
        background-color: #fff;
    }

    .scroll-none::-webkit-scrollbar {
        width: 0px;
    }
     /* scrollbar*/
     /* profile image */
    .image--cover {
      width: 150px;
      height: 150px;
      border-radius: 5%;
      margin: 20px;
      border: 1px solid #F562C1;      ;
      object-fit: cover;
      object-position: center right;
    }

    /* player style */
    .vs-Card {
      text-align: center; /* Center the text and image */
    }
    .bg-image-team{
      width: 6em;
      height: 6em;
      border-radius: 4px;
      background-size: cover;
      margin: auto auto 1em auto;
      background-position: center;
      background-repeat: no-repeat;
    }
    .bg-image-container {
      width: 50%;
      height: 150px; /* Set a fixed height for the container */
      border-radius: 4px;
      background-size: cover; /* Ensure the image covers the container */
      background-position: top; /* Center the image in the container */
      background-repeat: no-repeat; /* Prevent the image from repeating */
    }
    .vs-Card p{
      color: darkgoldenrod;
      font-weight: 600;
    }
    .middle-name{
      text-transform: capitalize;
      color: darkcyan;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .player-name {
      text-align: center; /* Center the name text */
      margin-top: 10px; /* Space between the image and the text */
    }
    .trophy-img-score {
      height: 30px !important;
      width: 50px;
      position: absolute;
      left: 1.3em;
    }