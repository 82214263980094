.corporate_standings table {
    --cui-table-bg:transparent;
    --cui-table-color:#fff;
}

.corporate_standings table thead {
    /* background-color:#EF2A2A; */
    background-color :#EF2A2A;

}

.corporate_standings svg {
    width: 1.5rem;
    margin-right: .3rem;
}

.corporate_standings table tr {
    transition: all .2s ease-in-out;
}

.corporate_standings table tr:hover {
    background-color :#EF2A2A;
}
@media (max-width: 767px) {
  .text-size {
    font-size: 3vw; /* Adjust the font size as needed */
  }
  thead th{
    font-size: 2.9vw!important;
    text-align: center;
  }
  tbody td{
    font-size: 2.7vw!important;
  }
  #corporateStandings{
        text-align: center!important;
        display: flex!important;
        justify-content: center!important;
        margin-bottom: 7%!important;
        font-size: 4vw!important;
        padding-right: 0%!important;
    }
    .col-group{
      display: flex!important;
    justify-content: center!important;
    }
    #drpBtn{
      font-size: 4vw!important;
  }
  .table-th{
    text-align: center !important;
    padding-bottom: 6vw !important;
  }
  .table-thh{
    text-align: center !important;
    padding-bottom: 2vw !important;
  }
}
