// Here you can add other styles
.card-img {
    width: 125px;
    height: 125px;
    margin: auto;
    margin-top: 35px;
    border-radius: 10%;
}
.player-card{
    margin-bottom:15px;
}

//group
.group-table-img{
    width:40px; 
    height: 40px;
    border-radius:50%;
    margin-left:10px;
    object-fit: cover;
}

.playerAdd{
    margin:auto;
    width:125px;
    height:125px;
}

.vs-Card{
    text-align: center;
    padding-top:20px;
}

.vs-Card img{
    width: 125px;
    height: auto;
    margin: auto;
    border-radius: 10%;
}

.vs-Card p{
    margin:0px;
    margin-top:10px;
    
}

.vs-tag{
    font-size:20px;
    text-align: center;
}
.vs-tag p{
    margin-bottom:15px !important;
}

.svg-icon{
    width: 25px;
    height: 25px;
    margin-left: 2px;
    margin-right: 2px;
}

.custom-button-icon{
    width:18px;
    height: 18px;
}


.score-success{
    color:#249542;
}

.score-danger{
    color: #E07575;
}

.card-points{
    font-size: 25px;
    line-height: 4;
}

// Scorer
.scorer-tab{
    gap:0;
    margin-top:3em;
    margin-bottom: 2em;
}
.scorer-tab .scorer-tab-link{
    color:#000;
    font-weight: 600;
    font-size:20px;
    border-bottom:0px;
}

.scorer-tab .scorer-tab-item{
    border-bottom:4px solid #A2A2A2;
    padding-left:12px;
    padding-right: 12px;
}

.scorer-tab .scorer-tab-item:has(> .active){
    border-color:#EF2A2A;
}

.scorer-card-title{
    background: #EF2A2A;
    text-align: center;
    padding: 13px;
    font-size: 20px;
    font-weight: 700;
    width: 28%;
    border-radius: 14px 0px 15px 0px;
    color: #fff;
}

.scorer-card-button{
    background: #EF2A2A;
    color: #fff;
    text-align: center;
    padding: 13px;
    font-size: 20px;
    font-weight: 700;
    width: 28%;
    border-radius: 0px 15px 0px 14px;
}

.flex-container {
    display: flex;
    width:100%;
}

.score-increase img, .score-less img{
    width:30px;
}

.scorer-img{
    width: 130px;
}
.scorer-name{
    padding-top: 10px;
    line-height: 24px;
    text-align: center !important;
}

.Scorer-submit{
    color: #fff;
    background: #275E9A;
    padding: 12px 65px;
    font-size: 20px;
}
.Scorer-submit:hover{
    color: #fff;
    background: #275E9A;
    padding: 12px 65px;
    font-size: 20px;
}

.scorer-place{
    font-size: 18px;
    line-height: 50px;
}

.scorer-date{
    font-size: 18px;
    line-height: 50px;
}

.card-background{
    position: absolute;
    top : 0px;
    right: -1px;
    width: 55px;
    height: 70px;
    background-size: cover;
    background-position: center;
    z-index: 1; 
    opacity: 0.5;
}

.scorer-card-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
}